import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import {
  BlueButtonBasic,
  Content,
  SEO,
  Spacing,
  Stack,
  Text,
  WhiteButton,
  devices,
} from '../components/common';
import HeroSection from '../components/HeroSection';
import { Employee, GuidancePage } from '../@types/types';
import Article from '../components/Article';
import GridBlock from '../components/ThreeSquareSection/GridBlock';
import EmployeeDisplay from '../components/EmployeeDisplay';
import Markdown from '../components/Markdown';
import { ExternalLink } from '../components/SVG';
import Employees from '../components/Employees';

type Props = {
  data: { page: GuidancePage; arrow: { publicURL: string } };
  location: Location;
};

/**
 * Professional guidance -page ("Työnohjaus")
 */
export default function Guidance({ data, location }: Props) {
  const {
    page: {
      MetaData,
      Content: content,
      ContentTitle,
      PageTopSection,
      FirstSectionTitle,
      FirstSectionText,
      FirstSectionPicture,
      SecondSectionTitle,
      SecondSectionText,
      SecondSectionPicture,
      employees,
    },
  } = data;

  const theme = useTheme();

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />
      <HeroSection topSection={PageTopSection} />
      <Article
        title={ContentTitle}
        backgroundColor={theme.colors.mutedLightBlue}
        content={content}
      />
      <GridBlock
        title={FirstSectionTitle}
        content={FirstSectionText}
        boxColor="lightBlue"
        picture={FirstSectionPicture}
      />
      <GridBlock
        title={SecondSectionTitle}
        content={SecondSectionText}
        picture={SecondSectionPicture}
        boxColor="lightBlue"
        reverse
      />

      <Employees employees={employees} />
    </>
  );
}

export const guidanceQuery = graphql`
  query GuidancePageQuery {
    page: strapiTyonohjaus {
      PageTopSection {
        PageTitle
        TopSectionText
      }
      MetaData {
        MetaDescription
        MetaTitle
        metaKeywords
      }
      Content
      ContentTitle
      FirstSectionTitle
      FirstSectionText
      FirstSectionPicture {
        publicURL
      }
      SecondSectionTitle
      SecondSectionText
      SecondSectionPicture {
        publicURL
      }
      employees {
        Description
        Details
        Email
        Name
        PhoneNumber
        ReservationLink
        Title
        id
        Picture {
          publicURL
        }
      }
    }
  }
`;
